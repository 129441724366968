import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Button, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | FEEL Tennis Academy
			</title>
			<meta name={"description"} content={"Birinci Sınıf Tenis Deneyiminiz"} />
			<meta property={"og:title"} content={"Home | FEEL Tennis Academy"} />
			<meta property={"og:description"} content={"Birinci Sınıf Tenis Deneyiminiz"} />
			<meta property={"og:image"} content={"https://amberglowsanctum.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://amberglowsanctum.com/img/45386854.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://amberglowsanctum.com/img/45386854.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://amberglowsanctum.com/img/45386854.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://amberglowsanctum.com/img/45386854.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://amberglowsanctum.com/img/45386854.png"} />
			<meta name={"msapplication-TileImage"} content={"https://amberglowsanctum.com/img/45386854.png"} />
		</Helmet>
		<Components.Header />
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				>
					<Image src="https://amberglowsanctum.com/img/1.jpg" display="block" width="100%" />
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						FEEL Kortlarına Hoş Geldiniz!
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
					Birinci sınıf bir oyun deneyimi arayan tenis tutkunları için nihai hedef olan FEEL Tennis Academy'a hoş geldiniz demekten mutluluk duyuyoruz. Son teknoloji ürünü toprak kortlarımız, performans ve konforun mükemmel bir karışımını sunarak her oyunun hem keyifli hem de rekabetçi olmasını sağlar. İster tecrübeli ister yeni başlayan bir oyuncu olun, tesislerimiz her türlü ihtiyacınızı karşılayacak ve kortta geçirdiğiniz zamanı unutulmaz kılacak şekilde tasarlanmıştır.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					>
						<Button
							background="#f48771"
							margin="0px 5px 0px 0px"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
							padding="8px 30px 8px 30px"
							sm-margin="0 0 8px 0"
						>
							Bize Ulaşın
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 8px 0px"
					font="--lead"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 16px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					<Strong sm-text-align="left">
						FEEL Tennis Academy
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					FEEL Tennis Academy'un Avantajları
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					FEEL Kortları: Her Maçın Unutulmayacak Bir Maç Olduğu Yer
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Üstün Kort Kalitesi
							</Strong>
						</Text>
						<Text
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Kortlarımızın bakımı en yüksek standartlarda yapılarak tutarlı ve güvenilir bir oyun yüzeyi sağlanır.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Profesyonel Koçluk
							</Strong>
						</Text>
						<Text
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							İster yeni başlayan ister ileri seviyede bir oyuncu olun, oyununuzu geliştirmenize yardımcı olabilecek deneyimli koçlara erişim.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Kapsamlı Olanaklar
							</Strong>
						</Text>
						<Text
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Tüm tenis ihtiyaçlarınız için konforlu dinlenme alanlarımızın, soyunma odalarımızın ve profesyonel mağazamızın keyfini çıkarın.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Esnek Programlama
							</Strong>
						</Text>
						<Text
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Yoğun programınıza uyacak çeşitli kort rezervasyonu seçenekleri sunarak sizin için en uygun zamanda oynayabilmenizi sağlıyoruz.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});